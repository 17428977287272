import { createApp } from 'vue';
import App from './App.vue';
import { createI18n } from 'vue-i18n/dist/vue-i18n.esm-bundler.js';
import en from './locales/en.json';
import zh from './locales/zh.json';

const i18n = createI18n({
  locale: 'en', // 设置默认语言
  fallbackLocale: 'zh', // 设置回退语言
  messages: {
    en,
    zh
  }
});

const app = createApp(App);
app.use(i18n);
app.mount('#app');
