<template>
  <div class="main">
    <div class="console-window">
      <div style="width: 100%; height:40px; background-color: white; color: black;">
        Terminal
      </div>
      <div class="console-content" ref="consoleContent">
        <div v-for="item in msgShown" :key="item.id" style="display: flex;   text-align: left;">
          <div style="flex-shrink: 0; width: 30px;">$</div>
          <div :style="{ color: item.text===selectedOption? 'blue':item.color }" style="  text-align: left;">{{ item.text }}</div>
        </div>
        <div style="display: flex;">
          <div>$ &nbsp;</div>
          <div autofocus class="input" contenteditable="true"></div>
        </div>
      </div>
    </div>
    <div style="color: white;">
      <div style="margin-bottom:20px; display: flex;">
        <div @click="handleChangeLanguage" style="cursor: pointer;" :style="{color: locale === 'zh' ? 'blue' : 'white'}">
          中
        </div>
        /
        <div @click="handleChangeLanguage" style="cursor: pointer;" :style="{color: locale === 'en' ? 'blue' : 'white'}">
          EN
        </div>
      </div>
      <div style="display: flex; gap:5px; align-items: center; cursor: pointer;" @click="redirectToUrl('https://github.com/dotnet-7')">
        <svg width="32" height="32" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <rect width="16" height="16" fill="#000"/>
        <path fill="#fff" d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27s1.36.09 2 .27c1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.013 8.013 0 0 0 16 8c0-4.42-3.58-8-8-8z"/>
        </svg>
        <div>
          https://github.com/dotnet-7
        </div>
      </div>
      <h5>Language: </h5>
      <div style="display: flex; align-items: center; gap: 10px;  justify-content: space-between; width: 100%;">
        &nbsp;
        English
        <div alt="进度条" style="position: relative; border: 1px solid white; width:200px; height: 20px; border-radius: 10px; overflow: hidden;">
          <div style="position: absolute; left: 0; top:0; height: 100%; background-color: white; width: 75%;"></div>
        </div>
      </div>
      <div style="display: flex; align-items: center; gap: 10px;  justify-content: space-between; width: 100%;">
        &nbsp;
        Chinese
        <div alt="进度条" style="position: relative; border: 1px solid white; width:200px; height: 20px; border-radius: 10px; overflow: hidden;">
          <div style="position: absolute; left: 0; top:0; height: 100%; background-color: white; width: 100%;"></div>
        </div>
      </div>
      <h5>Skills: </h5>
      <div class="skill-item" style="display: flex; align-items: center; gap: 10px; justify-content: space-between; width: 100%;" v-for="item in skills" :key="item">
        &nbsp;
        {{ item.label }}
        <div alt="进度条" style="position: relative; border: 1px solid white; width:200px; height: 20px; border-radius: 10px; overflow: hidden;">
          <div style="position: absolute; left: 0; top:0; height: 100%; background-color: white; width: 80%; " :style="{width: item.value + '%'}"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onUpdated, onMounted, nextTick } from 'vue'

import { useI18n } from 'vue-i18n';

const { t,locale } = useI18n();


async function initializeLocale() {
  const storedLocale = localStorage.getItem('userLocale');
  if (storedLocale) {
    locale.value = storedLocale;
  } else {
    // 默认语言设置
    locale.value = 'en';
  }
}

onMounted(async() => {
  await initializeLocale();
  main();
});


const msgShown = ref([]);
const options = ref([]);
const selectedOption = ref('');
const isListening = ref(false);
const consoleContent = ref(null);

const skills = ref([
  {
    label:'Python',
    value: 80
  },
  {
    label:'Golang',
    value:60,
  },
  {
    label:'Vue',
    value:80
  },
  {
    label:'C#',
    value:50
  }
])

function scrollToBottom() {
  nextTick(() => {
    if (consoleContent.value) {
      consoleContent.value.scrollTop = consoleContent.value.scrollHeight;
    }
  });
}

async function addText(text, type = 'text', color = 'white', delayBetweenChars = 50, delayAfterLine = 300) {
  const lines = text.split('\n'); // 分割文本为多行
  for (let line of lines) {
    const startIndex = msgShown.value.length;
    let displayText = '';

    for (let char of line) {
      await new Promise(resolve => setTimeout(resolve, delayBetweenChars));
      displayText += char;
      msgShown.value[startIndex] = { id: startIndex, text: displayText, color: color, type: type };
    }

    await new Promise(resolve => setTimeout(resolve, delayAfterLine));

    if (type === 'option') {
      options.value.push(line);
      if (options.value.length === 1) {
        selectedOption.value = line; // 默认选中第一个选项
      }
    }
    scrollToBottom();
  }
}

async function main() {
  await addText(t('welcome'));
  await addText(t('myName'), 'text', 'green');
  await addText(t('softwareEngineer'), 'text', 'green');
  await addText(t('selectOption'), 'text', 'green');
  await addText(t('studyExperience'), 'option', 'white');
  await addText(t('workExperience'), 'option', 'white');
  await addText(t('projects'), 'option', 'white');
  await addText(t('skills'), 'option', 'white');
  await addText(t('contact'), 'option', 'white');
  isListening.value = true;
}



const information = {
  [t('studyExperience')]: t('information.studyExperience'),
  [t('workExperience')]: t('information.workExperience'),
  [t('projects')]: t('information.projects'),
  [t('skills')]: t('information.skills'),
  [t('contact')]: t('information.contact')
};

window.addEventListener('keydown', async(event) => {
  if (!isListening.value) return;

  if (event.key === 'ArrowUp') {
    const index = options.value.indexOf(selectedOption.value);
    if (index > 0) {
      selectedOption.value = options.value[index - 1];
    }
  } else if (event.key === 'ArrowDown') {
    const index = options.value.indexOf(selectedOption.value);
    if (index < options.value.length - 1) {
      selectedOption.value = options.value[index + 1];
    }
  }
  if (event.key === 'Enter') {
    const text = selectedOption.value;
    await addText(information[text], 'text', 'white');
    isListening.value = false;
    options.value = [];
    await addText(t('selectOption'), 'text', 'green');
    await addText(t('studyExperience'), 'option', 'white');
    await addText(t('workExperience'), 'option', 'white');
    await addText(t('projects'), 'option', 'white');
    await addText(t('skills'), 'option', 'white');
    await addText(t('contact'), 'option', 'white');
    isListening.value = true;
  }
});

function redirectToUrl(url) {
  window.open(url, '_blank');
}

function handleChangeLanguage() {
  if (locale.value === 'en') {
    locale.value = 'zh';
  } else {
    locale.value = 'en';
  }
  localStorage.setItem('userLocale', locale.value);

  // 刷新页面
  window.location.reload();
}
</script>

<style>
#app {
  width: 100%;
  height: 100vh;
}

html, body, #app {
  height: 80vh;
  margin: 0;
  padding: 1%;
  font-family: Consolas;
  font-size: 25px;
  background-color: #000000;
  box-sizing: border-box;
}

.main {
  display: flex;
  gap: 10%;
  justify-content: center;
}

.console-content {
  position: relative;
  font-family: Consolas;
  overflow-y: auto;
  height: calc(100% - 40px);
  max-height: 500px;
}

.console-window {
  position: relative;
  width: 500px;
  height: 50%;
  background-color: #000000;
  color: white;
  padding: 0;
  box-sizing: border-box;
  font-family: Consolas;
  border: 1px solid white;
  box-shadow: 1 1 10px rgba(148, 148, 148, 0.5);
}

.input {
  background-color: transparent;
  border: none;
  overflow: hidden;
  resize: none;
  color: white;
  font-family: Consolas;
  font-size: 25px;
  outline: none;
  display: flex;
  min-width: 150px;
  align-items: center;
}
</style>
